<template>
  <div class="d-flex justify-space-between justify-center align-center" :class="customClass" v-if="instance.getUserPrivileges('wordpress.manage_auto_backup')">
    <div class="d-flex flex-column justify-center w-100">
      <h6 v-if="!small" class="font-weight-light">
        <template v-if="!loading">{{ $t('heading.instance.siteDetails.security.automaticBackup.title') }}</template>
        <v-skeleton-loader
          v-else
          class="title-skeleton title-skeleton--big"
          width="200px"
          height="24px"
          type="text"
        />
      </h6>
      <p v-else class="mb-0 p-2 black--text">
        <span v-if="!loading">{{ $t('general.backup') }}</span>
        <v-skeleton-loader v-else class="title-skeleton" type="text" />
      </p>

      <p
        v-if="
          !loading && automaticBackups.frequency !== 'never' && displaySettings
        "
        @click="openEditSettingsModal"
        class="p-4 cursor-pointer mb-0 line-16"
        :class="!small ? 'info--text' : 'info--text-hover'"
      >
        {{ displaySettings }}. <b v-if="!small" class="info--text">{{ $t('button.change') }}</b>
      </p>
      <div v-else class="w-100 d-flex align-center">
        <v-skeleton-loader v-if="loading" class="text-skeleton" type="text" />
        <span class="error--text font-weight-bold d-flex align-center" v-else>
          <v-icon color="error" size="16">$alertwarning</v-icon>
          <span class="p-3 ml-2 mt-1">{{ $t("message.status.disabled")}}</span>
        </span>
      </div>
    </div>

    <v-dialog width="560px" v-model="modalOpen" transition="custom-dialog-transition">
      <div class="card-overlay" @click="modalOpen = !modalOpen" />
      <automatic-backups-modal
        style="width: 560px"
        :edit="edit"
        :open="modalOpen"
        :settings="automaticBackups"
        :enabledFrequency="automaticBackups.frequency"
        @modal-close="modalClose"
        @reload-settings="reloadSettings"
      />
    </v-dialog>

    <v-menu
      v-if="!loading"
      offset-y
      translate="slide-y"
      elevation="4"
      nudgeBottom="8px"
      min-width="290px"
      content-class="attached"
      :attach="switchElement"
      z-index="9999"
      left
      v-model="confirmationOpen"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ attrs }">
        <template v-if="!asButton">
          <div
            class="switch-container pl-1 ml-2 d-flex justify-center"
            v-bind="attrs"
            @click="handleSwitchChange"
          >
            <v-switch
              id="#switch"
              ref="switch"
              :input-value="computedEnabled"
              class="mt-0 pt-0"
              hide-details=""
              flat
              :disabled="!editable"
            >
            </v-switch>
          </div>
        </template>
        <template v-else-if="asButton">
          <v-btn
            elevation="0"
            class="mt-0 pt-0"
            id="#switch"
            ref="switch"
            outlined
            v-bind="attrs"
            @click="handleSwitchChange"
            color="gray"
            x-small
            :disabled="!editable"
          >
            <span class="heading--text font-weight-bold p-4">
              <span v-if="computedEnabled">{{ $t('button.disable') }}</span>
              <span v-else>{{ $t('button.activate') }}</span>
            </span>
          </v-btn>
        </template>
      </template>
      <automatic-backups-confirmation
        :open="confirmationOpen"
        :settings="automaticBackups"
        :instance="instance"
        @reload-settings="reloadSettings"
        @card-close="confirmationOpen = false"
      />
    </v-menu>
    <div class="d-flex align-center" v-else>
      <v-skeleton-loader type="heading" class="switch-skeleton" />
    </div>
  </div>
</template>

<script>
import AutomaticBackupsModal from "../modal/AutomaticBackupsModal.vue";
import AutomaticBackupsConfirmation from "../modal/AutomaticBackupsConfirmation";

export default {
  components: {
    AutomaticBackupsConfirmation,
    AutomaticBackupsModal,
  },
  props: {
    customClass: String,
    small: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    automaticBackups: Object,
    instance: Object,
    asButton: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      edit: false,
      modalOpen: false,
      confirmationOpen: false,
    };
  },
  computed: {
    switchElement: function () {
      return this.$refs.switch;
    },
    computedEnabled: {
      get: function () {
        if (this.automaticBackups.frequency) {
          return this.automaticBackups.frequency !== "never" ? true : false;
        } else {
          return false;
        }
      },
      set: function (newValue) {
        this.handleSwitchChange(newValue);
      },
    },
    displayTime: {
      get: function () {
        let displayHour = 0;
        let time = " " + this.$t('general.am');
        if (this.automaticBackups.cron_hour / 12 > 1) {
          displayHour = this.automaticBackups.cron_hour - 12;
          time = " " + this.$t('general.pm');
        } else {
          displayHour = this.automaticBackups.cron_hour;
        }

        return `${displayHour}:${this.automaticBackups.cron_minute.padStart(
          2,
          "0"
        )} ${time}`;
      },
    },
    displaySettings: {
      get: function () {
        let messageString = "";

        if (this.automaticBackups.frequency === "daily") {
          messageString = this.$t('message.backup.everyDay', {time: this.displayTime});
        } else if (this.automaticBackups.frequency === "weekly") {
          messageString = this.$t('message.backup.everyWeek', {
            day: this.cronWeekdayToString(this.automaticBackups.cron_day_of_week),
            time: this.displayTime
          });
        } else if (this.automaticBackups.frequency === "monthly") {
          messageString = this.$t('message.backup.everyMonth', {
            day: this.cronMonthDaytoString(this.automaticBackups.cron_day_of_month),
            time: this.displayTime
          });
        } else {
          messageString = false;
        }

        return messageString;
      },
    },
  },
  watch: {
    modalOpen: function(value) {
      value ? this.$store.dispatch('lockBodyScroll') : this.$store.dispatch('unlockBodyScroll')
    }
  },
  methods: {
    modalClose: function () {
      this.edit = false;
      this.modalOpen = false;
    },
    cronMonthDaytoString: function (day) {
      if (day === 1) {
        return `${day}st`;
      } else if (day === 2) {
        return `${day}nd`;
      } else if (day === 3) {
        return `${day}rd`;
      } else {
        return `${day}th`;
      }
    },
    cronWeekdayToString: function (weekday) {
      const day = [
        this.$t('general.day.sunday'),
        this.$t('general.day.monday'),
        this.$t('general.day.tuesday'),
        this.$t('general.day.wednesday'),
        this.$t('general.day.thursday'),
        this.$t('general.day.friday'),
        this.$t('general.day.saturday'),
      ];

      return day[weekday];
    },
    reloadSettings: function () {
      this.$emit("reload-settings");
    },
    handleSwitchChange: function () {
      if (this.editable) {
        if (!this.computedEnabled) {
          this.openSettingsModal();
        } else {
          this.openConfirmationBox();
        }
      }
    },
    openEditSettingsModal: function () {
      this.edit = true;
      this.openSettingsModal();
    },
    openSettingsModal: function () {
      this.modalOpen = true;
    },
    openConfirmationBox() {
      this.confirmationOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
h6 {
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-weight: $font-weight-light;
  white-space: nowrap;
}
.switch-container {
  cursor: pointer;
  .v-input--switch {
    pointer-events: none;
  }
}

.title-skeleton.v-skeleton-loader::v-deep {
  .v-skeleton-loader__text {
    max-width: 100px;
    height: 16px;
    padding: 3px 0;
  }
  &.title-skeleton--big {
    .v-skeleton-loader__text {
      width: 160px;
      max-width: 160px;
      height: 16px;
      padding: 3px 0;
      margin-right: 16px;
    }
  }
}

.text-skeleton.v-skeleton-loader::v-deep {
  .v-skeleton-loader__text {
    max-width: 160px;
    height: 13px;
    padding: 2.5px 0;
  }
}

.switch-skeleton.v-skeleton-loader::v-deep {
  .v-skeleton-loader__heading {
    width: 45px;
    height: 28px;
    border-radius: 100px;
    padding: 1px 0;
  }
}

.info--text-hover {
  &:hover {
    color: map-get($info, base) !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.line-16 {
  line-height: $line-height-xxs;
  height: 16px;
}

.v-input.v-input--switch::v-deep {
  .v-input__control .v-input__slot {
    min-height: 40px;
  }
}
</style>